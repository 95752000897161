<template>
  <b-field label="Simple" grouped group-multiline>
    <b-select
      v-model="selectedId"
      placeholder="Select a name"
      style="max-width: 100%"
    >
      <option
        v-for="dossier in dossiers"
        :value="dossier.id"
        :key="dossier.id"
        style="max-width: 100%"
      >
        {{ dossier.description }}
      </option>
    </b-select>
    <p class="control">
      <button type="button" class="button is-primary" @click="showDossier()">
        View Dossier
      </button>
    </p>
  </b-field>
</template>

<script>
export default {
  props: {
    dossiers: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      selectedId: null,
    };
  },
  methods: {
    showDossier: function () {
      this.$emit("show-dossier", this.selectedId);
    },
  },
};
</script>

<style></style>
